import React from 'react';
import { Routes, Route } from "react-router-dom";
import {IconSettings} from '@salesforce/design-system-react';
import MultipleArticleBlock from '../multiple-article-block/MultipleArticleBlock';
import './App.css';
import Home from '../home/Home';

const App = () => {
  return (
    <IconSettings iconPath="/assets/icons">
      <div className='content'>
        <Routes>
          <Route index element={<Home/>} />
          <Route path="/multiple-article-block" element={<MultipleArticleBlock/>} />
        </Routes>
      </div>
    </IconSettings>
  );
}

export default App;
