import React from 'react';
import cx from 'classnames';
import Dropzone, {FileWithPath, FileRejection, DropEvent} from 'react-dropzone';
import {Button} from '@salesforce/design-system-react';
import FormElement from './FormElement';
import FormElementControl from './FormElementControl';
import FormElementError from './FormElementError';

const preventDefault = (e: any) => e.preventDefault();


export interface FileSelectorProps {
  id: string;
  buttonText: string;
  label: string;
  selectorText: string;
  value?: FileWithPath[];
  selectorType?: "files" | "images";
  minSize?: number;
  maxSize?: number;
  accept?: string;
  error?: string;
  fatalError?: string;
  multiple?: boolean;
  onDrop: (acceptedFiles: FileWithPath[], rejectedFiles: FileRejection[], e: DropEvent) => void;
  onDragEnter?: React.DragEventHandler<HTMLElement>;
  onDragLeave?: React.DragEventHandler<HTMLElement>;
}

const FileSelector: React.FunctionComponent<FileSelectorProps> = (props) => {
  const errorStr = props.fatalError || props.error;

  const fileSelectorClasses = [
    'slds-file-selector',
    `slds-file-selector_${props.selectorType || "files"}`
  ];

  const primaryLabelId = `${props.id}-primary-label`;
  const secondaryLabelId = `${props.id}-secondary-label`;

  return (
      <FormElement error={errorStr}>
        <span className="slds-form-element__label" id={primaryLabelId}>
          {props.label}
        </span>
        <FormElementControl>
          <div className={cx(fileSelectorClasses)}>
            <Dropzone
              accept={props.accept}
              disabled={!!props.fatalError}
              minSize={props.minSize || 0}
              maxSize={props.maxSize}
              multiple={props.multiple || false}
              onDrop={props.onDrop}
              onDragEnter={props.onDragEnter}
              onDragLeave={props.onDragLeave}
              
            >
              {({
                getRootProps,
                getInputProps,
                isDragActive,
              }: any) => (
                <div
                  {...getRootProps({
                    className: cx([
                      'slds-file-selector__dropzone',
                      { 'slds-has-drag-over': isDragActive && !props.fatalError },
                    ])
                  })}
                >
                  <input
                    {...getInputProps({
                      'aria-labelledby': `${primaryLabelId} ${secondaryLabelId}`,
                      className: 'slds-file-selector__input slds-assistive-text',
                      disabled: props.fatalError,
                      id: props.id
                    })}
                  />
                  <label
                    className="slds-file-selector__body"
                    htmlFor={props.id}
                    // Fixes dialog not working due to default `label<>input` event handling (Safari,Chrome)
                    onClick={preventDefault}
                    id={secondaryLabelId}
                  >
                    <Button
                      iconCategory="utility"
                      iconName="upload"
                      iconPosition="left"
                      iconSize='medium'
                      label={props.buttonText}
                    />
                    <span className="slds-file-selector__text">
                      {props.selectorText}
                    </span>
                  </label>
                  {props.value && props.value.map((file) => 
                    <div>
                      {file.name} 
                      <br/>
                    </div>
                  )}
                </div>
              )}
            </Dropzone>
          </div>
        </FormElementControl>
        <FormElementError error={errorStr} id={props.id} />
      </FormElement>
  );
};

export default FileSelector;