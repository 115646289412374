import React, { Key, createRef } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './RichTextEditor.css';

interface FontSize {
    label: string;
    value: number;
}

interface FontSizeConfig {
    sizes: FontSize[] | number[],
    unit?: string;
    default?: number;
}

export interface RichTextEditorProps {
    /**
    * Toolbars can be identified using this key
    */
    uniqueKey: Key;
    value?: string;
    defaultValue?: string;
    onChange?: ((content: string) => void);
    formats?: string[];
    fontSizeConfig?: FontSizeConfig;
    fonts?: string[];
    textColors?: string[];
    readonly?: boolean;
}

const quillFormats = [
    'header', 'size', 'font',
    'bold', 'italic', 'underline', 'strike',
    'list', 'bullet',
    'script', 'super',
    'color',
    'link', 'line-height', 'font-weight'
]

const defaultFontSizeConfig: FontSizeConfig = {
    sizes: [12, 14, 16, 18, 20, 22, 24, 36, 48, 72],
    default: 14,
    unit: 'px'
}

const Parchment = Quill.import('parchment');
const lineHeightConfig = {
    scope: Parchment.Scope.INLINE,
    whitelist: ['1', '1.2', '1.5', '1.6', '1.8', '2', '2.4', '2.8', '3', '4', '5']
};

const fontWeightConfig = {
    scope: Parchment.Scope.INLINE,
    whitelist: ['100', '200', '300', '400', '500', '600', '700', '800', '900']
};


const defaultFonts = ["Arial", "Courier New", "Georgia", "Helvetica"];

const RichTextEditor: React.FunctionComponent<RichTextEditorProps> = (props) => {

    const [initialized, setInitialized] = useState(false);

    const quillModules = {
        toolbar: {
            container: `#toolbar-${props.uniqueKey}`
        }
    };

    const configureFontSizes = () => {
        const config = props.fontSizeConfig || defaultFontSizeConfig;

        const Size = Quill.import("attributors/style/size");
        Size.whitelist = config.sizes.map((size) => {
            if (typeof size === "number") {
                return `${size}${config.unit || defaultFontSizeConfig.unit}`
            } else {
                return `${size.value}${config.unit || defaultFontSizeConfig.unit}`
            }
        });
        Quill.register(Size, true);
    }

    const configureFonts = () => {
        const config = props.fonts || defaultFonts;

        const Font = Quill.import("attributors/style/font");
        Font.whitelist = config;
        Quill.register(Font, true);

        const lineHeightClass = new Parchment.Attributor.Class('line-height', 'ql-line-height', lineHeightConfig);
        const lineHeightStyle = new Parchment.Attributor.Style('line-height', 'line-height', lineHeightConfig);
        Parchment.register(lineHeightClass);
        Parchment.register(lineHeightStyle);

        const fontWeightClass = new Parchment.Attributor.Class('font-weight', 'ql-font-weight', fontWeightConfig);
        const fontWeightStyle = new Parchment.Attributor.Style('font-weight', 'font-weight', fontWeightConfig);
        Parchment.register(fontWeightClass);
        Parchment.register(fontWeightStyle);
    }

    useEffect(() => {
        configureFontSizes();
        configureFonts();
        setInitialized(true);
    }, []);

    const editorRef = createRef<ReactQuill>();

    return (
        <div>
            {initialized &&
                <div>
                    <CustomToolbar {...props} />
                    <ReactQuill
                        ref={editorRef}
                        value={props.value}
                        key={props.uniqueKey}
                        onChange={props.onChange}
                        defaultValue={props.defaultValue}
                        modules={quillModules}
                        formats={props.formats || quillFormats}
                        readOnly={props.readonly || false}
                        theme="snow"
                    />
                </div>
            }
        </div>
    );
}
export default RichTextEditor;

const CustomToolbar: React.FunctionComponent<RichTextEditorProps> = (props) => {

    const config = props.fontSizeConfig || defaultFontSizeConfig;
    const fonts = props.fonts || defaultFonts;

    const capitalizeFontName = (font: string) => {
        let capitalized = "";
        for (const word of font.toLowerCase().split(" ")) {
            capitalized += word.charAt(0).toUpperCase() + word.slice(1) + " ";
        }
        return capitalized.trim();
    }

    return (
        <div id={`toolbar-${props.uniqueKey}`}>
            <span className="ql-formats">
                <select className="ql-font" defaultValue={fonts[0]}>
                    {fonts.map((font, i) => {
                        return <option key={i} value={font}>{capitalizeFontName(font)}</option>
                    })}
                </select>
                <select className="ql-size" defaultValue={`${config.default || defaultFontSizeConfig.default}${config.unit || defaultFontSizeConfig.unit}`}>
                    {config.sizes.map((size, i) => {
                        return <option key={i} value={`${typeof size === "number" ? size : size.value}${config.unit || defaultFontSizeConfig.unit}`}>{typeof size === "number" ? size : size.label}</option>
                    })}
                </select>
                <select className="ql-line-height" defaultValue={"1.2"}>
                    {lineHeightConfig.whitelist.map((height, i) => {
                        return <option key={i} value={height}>{height}</option>
                    })}
                </select>
                <select className="ql-font-weight" defaultValue={"400"}>
                    {fontWeightConfig.whitelist.map((weight, i) => {
                        return <option key={i} value={weight}>{weight}</option>
                    })}
                </select>
                <select className="ql-header" defaultValue="3">
                    <option value="1">Heading</option>
                    <option value="2">Subheading</option>
                    <option value="3">Normal</option>
                </select>
            </span>
            <span className="ql-formats">
                <button className="ql-bold" />
                <button className="ql-italic" />
                <button className="ql-underline" />
                <button className="ql-strike" />
            </span>
            <span className="ql-formats">
                <button className="ql-script" value="super" />
                <button className="ql-link" />
                <select className="ql-color">
                    {props.textColors?.map((color, i) => 
                        <option key={i} value={color}></option>    
                    )}
                </select>
            </span>
            <span className="ql-formats">
                <button className="ql-list" value="bullet" />
            </span>
            <span className="ql-formats">
                <button className="ql-clean" />
            </span>
        </div>
    );
};