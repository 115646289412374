import React, { useCallback, useEffect, useState } from 'react';
import { ColorPicker, Input, RadioButtonGroup, Radio, Spinner, Card, Icon, Checkbox, Dropdown, Slider } from '@salesforce/design-system-react';
import debounce from 'lodash.debounce';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import './MultipleArticleBlock.css';
import { cloneDeep, toNumber } from 'lodash';
import generateHTML from './HTMLGenerator';
import RichTextEditor from '../ui/RichTextEditor';
import FileSelector from '../ui/FileSelector';
import axios from 'axios';
import config from '../../config';
import UploadImageModal from '../modals/UploadImageModal';


const BlockSDK = require('blocksdk');
const sdk = new BlockSDK({
  blockEditorWidth: 600,
  tabs: [
    'htmlblock',
  ]
}, null, true);

type Article = {
  title: string;
  subtitle: string;
  text: string;
  imageURL: string;
  name: string;
  URL: string;
  buttonText: string;
  buttonStyle: string;
  URL2: string;
  buttonTwoText: string;
  buttonTwoStyle: string;
  fixedDesktopHeight: number;
  articleBorder: boolean;
  articleTopSpacing:number;
  articleBottomSpacing:number;
}

const contentLayouts = [
  { label: "30%", value: 30 },
  { label: "40%", value: 40 },
  { label: "50%", value: 50 },
  { label: "70%", value: 70 },
  { label: "100%", value: 100 }
];

const textAlignment = [
  { label: "Left", value: "left" },
  { label: "Center", value: "center" },
  { label: "Right", value: "right" }
];

const settingTemplate = [
  { label: "Springbok", value: "springbok" },
  { label: "Renault", value: "renault" },
  { label: "Dacia", value: "dacia" },
  { label: "JBL", value: "jbl" },
  { label: "Harman", value: "harman" }
];

const bulletStyleOptions = [
  { label: "Bullet point", value: "bull" },
  { label: "Renault check black", value: "renaultCheckBlack" },
  { label: "JBL check orange", value: "jblCheckOrange" }
];

const buttonStyleOptions = [
  {
    label: 'Regular CTA',
    value: 'regular',
    rightIcon: {
      category: 'utility',
      name: 'record',
    },
  },
  {
    label: 'border CTA',
    value: 'border',
    rightIcon: {
      category: 'utility',
      name: 'routing_offline',
    },
  },
  {
    label: 'textual link',
    value: 'textual',
    rightIcon: {
      category: 'utility',
      name: 'dash',
    },
  },
];

export type MultipleArticleForm = {
  settingTemplate: 'springbok' | 'renault' | 'dacia' | 'jbl' | 'harman';
  backgroundColor: string;
  overruleFontColor: string;
  overruleButtonColor: string;
  overruleButtonTextColor: string;
  bulletStyle: string;
  textAlignment: string;
  settingPadding: boolean;
  columnDirection: boolean;
  contentLayout: 30 | 40 | 50 | 70 | 100;
  articles: Partial<Article>[];
}


const MAX_ARTICLES = 3;
const generateDefaultArticles = () => {
  const articles: Article[] = [];
  for (let i = 0; i < MAX_ARTICLES; i++) {
    articles.push({
      name: "",
      URL: "",
      buttonText: "",
      buttonStyle: "regular",
      URL2: "",
      buttonTwoText: "",
      buttonTwoStyle: "regular",
      imageURL: "",
      text: "",
      title: "",
      subtitle: "",
      fixedDesktopHeight: 0,
      articleBorder: false,
      articleTopSpacing: 15, 
      articleBottomSpacing: 15
    });
  }
  return articles;
}

const MyFirstBlock = () => {

  /**
   * Syntax for the form library
   */
  const { control, watch, setValue } = useForm<MultipleArticleForm>({
    defaultValues: {
      settingTemplate: "springbok",
      backgroundColor: "#ffffff",
      overruleFontColor: "",
      overruleButtonColor: "",
      overruleButtonTextColor: "",   
      bulletStyle: "bull",
      textAlignment: "left",
      settingPadding: false,
      columnDirection: false,
      contentLayout: 50,
      articles: generateDefaultArticles()
    },
  });
  const { fields } = useFieldArray({ name: 'articles', control });

  const [initialized, setInitialized] = useState(false);

  const [fileUploadModalOpen, setFileUploadModalOpen] = useState(false);
  const [fileUploadModalLoading, setFileUploadModalLoading] = useState(false);
  const [fileUploadModalError, setFileUploadModalError] = useState<string | undefined>();
  const [fileUploadFile, setFileUploadFile] = useState<{ file: File, articleIndex: number } | undefined>();

  /**
   * Watch every form value for changes, used in UseEffect(..., [data])
   */
  const data = watch();

  const submit = (data: MultipleArticleForm) => {
    let html = "";
    try {
      html = generateHTML(cloneDeep(data))
    } catch (e) {
      html = `<p style="color: red;">${e}</p>`;
    }
    sdk.setData(data);
    sdk.setContent(html);
  }
  /**
   * Lodash with React syntax, basically debounces the submit function with specified delay
   */
  const debounceSubmit = useCallback(debounce(submit, 500), []);

  /**
   * Update every time that the form data changes (with debounce)
   */
  useEffect(() => {
    if (initialized) {
      debounceSubmit(data);
    }
  }, [data]);

  const uploadFile = async (file: File, articleIndex: number) => {
    setFileUploadFile({ file, articleIndex });
    setFileUploadModalOpen(true);
  }

  const doUpload = (name: string) => {
    if (fileUploadFile) {
      setFileUploadModalLoading(true);
      window.addEventListener("message", function messageListener(e) {
        if (e.data === "cookieSet") {
          const formData = new FormData();
          formData.append("file", fileUploadFile.file);
          formData.append("name", name);
          axios.post(`${config.backend_base_url}/api/uploadFile`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
            .then((res) => {
              setFileUploadFile(undefined);
              setFileUploadModalOpen(false);
              if (res.data && res.data.fileProperties) {
                setValue(`articles.${fileUploadFile.articleIndex}.imageURL`, res.data.fileProperties.publishedURL)
              }
            }).catch(e => {
              if (e.response && e.response.data && e.response.data.validationErrors)
                setFileUploadModalError(e.response.data.validationErrors[0].message);
            })
            .finally(() => {
              setFileUploadModalLoading(false);
            });
          window.removeEventListener("message", messageListener);
        }
      });
      sdk.triggerAuth2({ authURL: `https://${config.sfmc_subdomain}.auth.marketingcloudapis.com`, clientId: config.sfmc_client_id, redirectURL: `${config.backend_base_url}/api/auth`, scope: ['documents_and_images_read', 'documents_and_images_write', 'saved_content_read', 'saved_content_write'] });
    }
  }

  /**
   * On initialize, set all form values from the Salesforce data
   */
  useEffect(() => {
    sdk.getData((data: any) => {
      for (const [key, value] of Object.entries(data)) {
        setValue(key as any, value as string);
      }
      setInitialized(true);
    });
  }, []);

  /**
   * A controller is a component which renders custom components (here: SLDS components)
   */
  return (<div>
    {!initialized ? <Spinner /> :
      <div>
        <form>
          <Card heading="Module settings" icon={<Icon category="standard" name="custom_component_task" size="small" />}>
            <div className='p-15'>
              <Controller
                name="settingTemplate"
                control={control}
                render={({ field: { onChange, value } }) =>
                  <RadioButtonGroup
                    value={value}
                    labels={{ label: "(POC) select template" }}
                    onChange={(event: any) => onChange(event.target.value)}
                    required={true}
                  >
                    {settingTemplate.map((template) => (
                      <Radio
                        key={template.value}
                        labels={{ label: template.label }}
                        value={template.value}
                        checked={value === template.value}
                        variant="button-group"
                      />
                    ))}
                  </RadioButtonGroup>
                }
              />
              <br />
              <Controller
                name="contentLayout"
                control={control}
                render={({ field: { onChange, value } }) =>
                  <RadioButtonGroup
                    value={value}
                    labels={{ label: "Content layout (doesnt work when using 3 articles)" }}
                    onChange={(event: any) => onChange(toNumber(event.target.value))}
                    required={true}
                  >
                    {contentLayouts.map((layout) => (
                      <Radio
                        key={layout.value}
                        labels={{ label: layout.label }}
                        value={layout.value.toString()}
                        checked={value === layout.value}
                        variant="button-group"
                      />
                    ))}
                  </RadioButtonGroup>
                }
              />
              <br />
              
                  <Controller
                    name="settingPadding"
                    control={control}
                    render={({ field: { onChange, value } }) => <Checkbox variant="toggle" labels={{ label: "Toggle outer padding" }} checked={value} onChange={(event: any, value: any) => onChange(value.checked)} />}
                  />
                  <br />
               
                  <Controller
                    name="columnDirection"
                    control={control}
                    render={({ field: { onChange, value } }) => <Checkbox variant="toggle" labels={{ label: "Toggle column direction (flip)" }} checked={value} onChange={(event: any, value: any) => onChange(value.checked)} />}
                  />
                  <br />
                
              <div className="slds-grid slds-gutters">
                <div className="slds-col slds-size_6-of-12">
                  <label className="slds-form-element__label"><b>Overwrite default colors:</b></label>
                  <Controller
                    name="backgroundColor"
                    control={control}
                    render={({ field: { onChange, value } }) => <ColorPicker labels={{ label: "Module background" }} value={value} events={{ onChange: (event: any, value: any) => onChange(value.color) }} />}
                  />
                  <br />
                </div>
                <div className="slds-col slds-size_6-of-12">
                  <br/>
                  <Controller
                    name="overruleFontColor"
                    control={control}
                    render={({ field: { onChange, value } }) => <ColorPicker labels={{ label: "Text" }} value={value} events={{ onChange: (event: any, value: any) => onChange(value.color) }} />}
                  />
                  <br />
                </div>
              </div>
              <div className="slds-grid slds-gutters">
                <div className="slds-col slds-size_6-of-12">
                  <Controller
                    name="overruleButtonColor"
                    control={control}
                    render={({ field: { onChange, value } }) => <ColorPicker labels={{ label: "Button background" }} value={value} events={{ onChange: (event: any, value: any) => onChange(value.color) }} />}
                  />
                  <br />
                </div>
                <div className="slds-col slds-size_6-of-12">
                  <Controller
                    name="overruleButtonTextColor"
                    control={control}
                    render={({ field: { onChange, value } }) => <ColorPicker labels={{ label: "Button text" }} value={value} events={{ onChange: (event: any, value: any) => onChange(value.color) }} />}
                  />
                  <br />
                </div>
              </div>
              <Controller
                name="textAlignment"
                control={control}
                render={({ field: { onChange, value } }) =>
                  <RadioButtonGroup
                    value={value}
                    labels={{ label: "Text alignment" }}
                    onChange={(event: any) => onChange(event.target.value)}
                    required={true}
                  >
                    {textAlignment.map((layout) => (
                      <Radio
                        key={layout.value}
                        labels={{ label: layout.label }}
                        value={layout.value}
                        checked={value === layout.value}
                        variant="button-group"
                      />
                    ))}
                  </RadioButtonGroup>
                }
              />
              <br />
              <label className="slds-form-element__label">Set bulletpoint style</label><br/>
              <Controller
                name="bulletStyle"
                control={control}
                render={({ field: { onChange, value } }) =>
                  <Dropdown
                    align="right"
                    iconCategory="utility"
                    iconName="down"
                    iconPosition="right"
                    label={bulletStyleOptions.find((option) => {
                      return option.value === value;
                    })?.label || "Pick an option"}
                    value={value}
                    options={bulletStyleOptions}
                    onSelect={(option: any) => {
                      onChange(option.value);
                    }}
                    iconSize="small"
                    menuPostition="relative"
                  />
                }
              />
              <br/>
            </div>
          </Card>
          <br />
          {fields.map((field, i) => (
            <Card key={field.id} heading={`Article ${i + 1}`} icon={<Icon category="standard" name="news" size="small" />}>
              <div className='p-15'>
                <Controller
                  name={`articles.${i}.name`}
                  control={control}
                  render={({ field: { onChange, value } }) =>
                    <Input
                      value={value}
                      onChange={(event: any, data: any) => onChange(data.value)}
                      required
                      label="Content name (used for tracking, avoid spaces)"
                    />}
                />
                <br />
                <label className="slds-form-element__label">Article title</label>
                <Controller
                  name={`articles.${i}.title`}
                  control={control}
                  render={({ field: { onChange, value } }) =>
                    <RichTextEditor
                      uniqueKey={`title-${i}`}
                      value={value}
                      onChange={onChange}
                    />}
                />
                <br />
                <Controller
                  name={`articles.${i}.subtitle`}
                  control={control}
                  render={({ field: { onChange, value } }) =>
                    <Input
                      value={value}
                      onChange={(event: any, data: any) => onChange(data.value)}
                      label="Subtitle"
                    />}
                />
                <br />
                <FileSelector
                  selectorText='or drop files here'
                  onDrop={(acceptedFiles) => { uploadFile(acceptedFiles[0] as File, i) }}
                  label='Image'
                  id={`${i}.imageUpload`}
                  accept='image/jpeg, image/png, image/gif'
                  buttonText='Upload' />
                <br />
                {<Controller
                  name={`articles.${i}.imageURL`}
                  control={control}
                  render={({ field: { onChange, value } }) =>
                    <Input
                      value={value}
                      onChange={(event: any, data: any) => onChange(data.value)}
                      label="Image URL"
                    />
                  }
                />}
                <br />
                <label className="slds-form-element__label">Article text</label>
                <Controller
                  name={`articles.${i}.text`}
                  control={control}
                  render={({ field: { onChange, value } }) =>
                    <RichTextEditor
                      uniqueKey={`text-${i}`}
                      value={value}
                      onChange={onChange}
                    />}
                />
                <br />
                <div className="slds-grid slds-gutters">
                  <div className="slds-col slds-size_10-of-12">
                    <Controller
                      name={`articles.${i}.buttonText`}
                      control={control}
                      render={({ field: { onChange, value } }) =>
                        <Input
                          value={value}
                          onChange={(event: any, data: any) => onChange(data.value)}
                          label="Button text"
                        />}
                    />
                  </div>
                  <div className="slds-col slds-size_2-of-12" style={{ marginTop: "25px" }}>
                    <Controller
                      name={`articles.${i}.buttonStyle`}
                      control={control}
                      render={({ field: { onChange, value } }) =>
                        <Dropdown
                          align={"right"}
                          assistiveText={{ icon: 'Checkmark with right icon' }}
                          checkmark
                          iconCategory="utility"
                          iconName="down"
                          iconVariant="border-filled"
                          buttonVariant="icon"
                          value={value}
                          options={buttonStyleOptions}
                          onSelect={(option: any) => {
                            onChange(option.value);
                          }}
                          iconSize="small"
                          menuPostition="relative"
                        />}
                    />
                  </div>
                </div>
                <br />
                <Controller
                  name={`articles.${i}.URL`}
                  control={control}
                  render={({ field: { onChange, value } }) =>
                    <Input
                      value={value}
                      onChange={(event: any, data: any) => onChange(data.value)}
                      required
                      label="URL for article"
                    />}
                />
                <br />
                <div className="slds-grid slds-gutters">
                  <div className="slds-col slds-size_10-of-12">
                    <Controller
                      name={`articles.${i}.buttonTwoText`}
                      control={control}
                      render={({ field: { onChange, value } }) =>
                        <Input
                          value={value}
                          onChange={(event: any, data: any) => onChange(data.value)}
                          label="Secondary Button text"
                        />}
                    />
                  </div>
                  <div className="slds-col slds-size_2-of-12" style={{ marginTop: "25px" }}>
                    <Controller
                      name={`articles.${i}.buttonTwoStyle`}
                      control={control}
                      render={({ field: { onChange, value } }) =>
                        <Dropdown
                          align={"right"}
                          assistiveText={{ icon: 'Checkmark with right icon' }}
                          checkmark
                          iconCategory="utility"
                          iconName="down"
                          iconVariant="border-filled"
                          buttonVariant="icon"
                          value={value}
                          options={buttonStyleOptions}
                          onSelect={(option: any) => {
                            onChange(option.value);
                          }}
                          iconSize="small"
                          menuPostition="relative"
                        />}
                    />
                  </div>
                </div>
                <br />
                <Controller
                  name={`articles.${i}.URL2`}
                  control={control}
                  render={({ field: { onChange, value } }) =>
                    <Input
                      value={value}
                      onChange={(event: any, data: any) => onChange(data.value)}
                      label="URL for button two"
                    />}
                />
                <br />
                <Controller
                    name={`articles.${i}.articleBorder`}
                    control={control}
                    render={({ field: { onChange, value } }) => <Checkbox variant="toggle" labels={{ label: "Toggle article border (inset)" }} checked={value} onChange={(event: any, value: any) => onChange(value.checked)} />}
                  />
                <br />
                <label className="slds-form-element__label"><b>Add extra spacing</b></label><br/>
                <div className="slds-grid slds-gutters">
                  <div className="slds-col slds-size_6-of-12">
                    <Controller
                      name={`articles.${i}.articleTopSpacing`}
                      control={control}
                      render={({ field: { onChange, value } }) =>
                        <Slider
                          value={value}
                          onChange={(event: any, data: any) => onChange(data.value)}
                          label="to top"
                        />}
                    />
                  </div>
                  <div className="slds-col slds-size_6-of-12">
                    <Controller
                        name={`articles.${i}.articleBottomSpacing`}
                        control={control}
                        render={({ field: { onChange, value } }) =>
                          <Slider
                            value={value}
                            onChange={(event: any, data: any) => onChange(data.value)}
                            label="to bottom"
                          />}
                      />
                  </div>
                </div>

                <Controller
                  name={`articles.${i}.fixedDesktopHeight`}
                  control={control}
                  render={({ field: { onChange, value } }) =>
                    <Input
                      value={value}
                      onChange={(event: any, data: any) => onChange(data.value)}
                      required
                      label="Force content height (desktop only)"
                    />}
                />
                <br />
              </div>
            </Card>
          ))}
        </form>
        <UploadImageModal onUpload={doUpload} open={fileUploadModalOpen} onRequestClose={() => {
          setFileUploadFile(undefined);
          setFileUploadModalError(undefined);
          setFileUploadModalLoading(false);
          setFileUploadModalOpen(false);
        }} loading={fileUploadModalLoading} error={fileUploadModalError} />
      </div>
    }
  </div>);
}
export default MyFirstBlock;