import React from 'react';
import cx from 'classnames';

import getUniqueHash from '../../utils/hashFromString';

export interface FormElementErrorProps {
    id: string;
    error?: string;
    className?: string;
    [key: string]: any;
}

const FormElementError: React.FunctionComponent<FormElementErrorProps> = (props) => {
    const {
        className, error, id, ...rest
    } = props;

    const renderError = () => {
        if (error) {
            const sldsClasses = [
                'slds-form-element__help',
                className
            ];

            return (
                <div
                    {...rest}
                    id={getUniqueHash(error, id)}
                    className={cx(sldsClasses)}
                >
                    {error}
                </div>
            );
        }

        return null;
    };

    return renderError();
};
export default FormElementError;