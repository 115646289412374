import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div>
      <Link to="multiple-article-block">Multiple article block</Link>
    </div>
  );
}
export default Home;
