import { Button, DynamicIcon, Input, Modal } from "@salesforce/design-system-react";
import { useState } from "react";

export interface UploadImageModalProps {
    open: boolean;
    loading?: boolean;
    heading?: string;
    error?: string;
    onUpload: (name: string) => void;
    onRequestClose: () => void;
}

const UploadImageModal: React.FunctionComponent<UploadImageModalProps> = (props) => {

    const [name, setName] = useState("");

    const close = () => {
        reset();
        props.onRequestClose();
    };

    const reset = () => {
        setName("");
    }

    return (
        <Modal
            heading={props.heading || "Upload file"}
            isOpen={props.open}
            onRequestClose={close}
            footer={!props.loading && [
                <Button label="Cancel" onClick={close} />,
                <Button disabled={name.trim() === '' || name.length < 3} label="Save" variant="brand" onClick={() => props.onUpload(name)} />,
            ]}
        >
            <section className="slds-p-around_large">
                {props.loading ? <div className="slds-align_absolute-center"><DynamicIcon title="Loading..." variant="typing" /></div> :
                    <Input 
                        value={name} 
                        onChange={(event: any, data: any) => setName(data.value)} 
                        required
                        label="File name"
                        errorText={props.error}
                    />
                }
            </section>
        </Modal>
    );
}
export default UploadImageModal;
