import React from 'react';
import cx from 'classnames';

export interface FormElementControlProps {
    children: React.ReactNode;
    className?: string;
    hasIconLeft?: boolean;
    hasIconRight?: boolean;
    [key: string]: any;
}

const FormElementControl: React.FunctionComponent<FormElementControlProps> = (props) => {
  const {
    children, className, hasIconLeft, hasIconRight, ...rest
  } = props;

  const sldsClasses = [
    'slds-form-element__control',
    { 'slds-input-has-icon': hasIconLeft || hasIconRight },
    { 'slds-input-has-icon_right': hasIconRight && !hasIconLeft },
    { 'slds-input-has-icon_left': hasIconLeft && !hasIconRight },
    { 'slds-input-has-icon_left-right': hasIconLeft && hasIconRight },
    className
  ];

  return (<div {...rest} className={cx(sldsClasses)}>{children}</div>);
};
export default FormElementControl;