import React from 'react';
import cx from 'classnames';

export interface FormElementProps {
    children: React.ReactNode;
    className?: string;
    error?: string;
    fieldset?: boolean;
    isStatic?: boolean;
    required?: boolean;
    [key: string]: any;
}

const FormElement: React.FunctionComponent<FormElementProps> = (props) => {

    const sldsClasses = [
        'slds-form-element',
        { 'slds-has-error': !!props.error },
        { 'slds-is-required': props.required || false },
        { 'slds-form-element_readonly': props.isStatic || false },
        props.className
    ];

    const Tag = (props.fieldset || false) ? 'fieldset' : 'div';

    return (<Tag {...props} className={cx(sldsClasses)}>{props.children}</Tag>);
};
export default FormElement;