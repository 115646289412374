import { MultipleArticleForm } from "./MultipleArticleBlock";

const isEmpty = (str: string | undefined) => {
    return !str || str.trim() === "";
}

const allEmpty = (strings: (string | undefined)[]) => {
    for(const str of strings) {
        if(!isEmpty(str)) {
            return false;
        }
    }
    return true;
}

const defaultColumn = {
	title: "",
	subTitle: "",
	text: "",
	button: "",
	desktopImage: "",
	imageWidth: 0,
	textHeight: "",
	width: 0,
	className: "", 
}

const generateDefaultColumns = (amount: number) => {
	const columns = [];
	for(let i = 0; i < amount; i++) {
		columns.push(defaultColumn);
	}
	return columns;
}

const generateHTML = (form: MultipleArticleForm): string => {
	const html = {
		headerContainer: "",
		headerContent: "",
		closeContent: "",
		closeColumn: "",
		closeContainer: "",
		fontStandard: "",
		fontTitle: "",
		fontSubtitle: "",
		fontButton: "",
		fontColor: "",
		buttonColor: "",
		buttonTextColor: "",
		buttonBorderRadius: "",
		buttonPadding: "",
		column: generateDefaultColumns(3)
	}


	let output = "", //html variables contain output HTML
		articles = 0,
		containerWidth = 0, //width of the template, used for width calculations
		padding = 0, //padding (left/right) - mobile inline - hardcoded in template overrule 
		paddingBottom = 0, //spacing below each element
		columnDirection = "", //ltr or rtl
		columnClass = "",
		columnWidth = 0,
		columnOneWidth = 0, //width calculated based on container size, layout, and padding setting
		columnOneClass = "", //class depends on layout selected
		columnTwoWidth = 0, //width calculated based on container size, layout, and padding setting
		columnTwoClass = "", //class depends on layout selected
		columnThreeWidth = 0, //width calculated based on container size, layout, and padding setting
		columnThreeClass = "", //class depends on layout selected
		innerPaddingClass = "",
		innerPaddingRight = "", //class placeholder, this class changes the padding on desktop compared to mobile. (e.g. mobile 20px desktop 10px)
		innerPaddingLeft = "" //class placeholder, this class changes the padding on desktop compared to mobile. (e.g. mobile 20px desktop 10px)

		//Settings per brand / templatge
		//Some of these settings may be overruled.
		if (form.settingTemplate === "renault"){
			html.fontColor = "#000000"
			html.buttonColor = "#EFDF00"
			html.buttonTextColor = "#000000"
			containerWidth = 750; //size is 'hardcoded' for 3 column in CSS. need to adjust for renault 750
			padding = 30; //size is 'hardcoded' for 3 column in CSS. need to adjust for renault
			paddingBottom = 15;
			html.buttonBorderRadius = "0px"
			html.buttonPadding = "15px 40px"
		}else if(form.settingTemplate === "dacia"){
			html.fontColor = "#000000"
			html.buttonColor = "#000000"
			html.buttonTextColor = "#ffffff"
			containerWidth = 750; //size is 'hardcoded' for 3 column in CSS. need to adjust for renault 750
			padding = 30; //size is 'hardcoded' for 3 column in CSS. need to adjust for renault
			paddingBottom = 15;
			html.buttonBorderRadius = "0px"
			html.buttonPadding = "15px 40px"
		}else if(form.settingTemplate === "jbl"){
			// fallback springbok
			html.fontColor = "#000000"
			html.buttonColor = "#FF3300"
			html.buttonTextColor = "#ffffff"
			containerWidth = 600; //size is 'hardcoded' for 3 column in CSS.
			padding = 30; //size is 'hardcoded' for 3 column in CSS.
			paddingBottom = 15;
			html.buttonBorderRadius = "40px"
			html.buttonPadding = "12px 48px"
		}else if(form.settingTemplate === "harman"){
			// fallback springbok
			html.fontColor = "#000000"
			html.buttonColor = "#6ba4b8"
			html.buttonTextColor = "#ffffff"
			containerWidth = 600; //size is 'hardcoded' for 3 column in CSS.
			padding = 30; //size is 'hardcoded' for 3 column in CSS.
			paddingBottom = 15;
			html.buttonBorderRadius = "40px"
			html.buttonPadding = "12px 48px"
		}else{
			// fallback springbok
			html.fontColor = "#6a7581"
			html.buttonColor = "#000000"
			html.buttonTextColor = "#ffffff"
			containerWidth = 720; //size is 'hardcoded' for 3 column in CSS.
			padding = 20; //size is 'hardcoded' for 3 column in CSS.
			paddingBottom = 15;
			html.buttonBorderRadius = "0px"
			html.buttonPadding = "10px 35px"
		}


	//fallbacks
	if(!form.textAlignment) {
		form.textAlignment = "left"
	}

	if(form.overruleButtonColor) {
		html.buttonColor = form.overruleButtonColor
	}

	if(form.overruleButtonTextColor) {
		html.buttonTextColor = form.overruleButtonTextColor
	}

	if(form.overruleFontColor) {
		html.fontColor = form.overruleFontColor
	}

	//bulletStyle options
	if(!form.bulletStyle || form.bulletStyle === "bull") {
		form.bulletStyle = "&bull;"
	}else if(form.bulletStyle === "renaultCheckBlack"){
		form.bulletStyle = "<img width=\"10\" src=\"https://image.mc.renault.com/lib/fe3111717564047c7c1377/m/2/TICK_BLACK.png\"/>"
	}else if(form.bulletStyle === "jblCheckOrange"){
		form.bulletStyle = "<img style=\"margin-top:7px;\" width=\"18\" src=\"https://image.cloud2.harmanaudio.com/lib/fe34157075640474751d78/m/4/46e552aa-be30-4814-980b-ade43ba4d59d.png\"/>"
	}

	if(!form.articles[1]) {
        form.articles[1] = {};
    }

    if(!form.articles[2]) {
        form.articles[2] = {};
    }

    //determine amount of articles used
    if (allEmpty([form.articles[1].title, form.articles[1].imageURL, form.articles[1].text, form.articles[1].buttonText, form.articles[1].buttonTwoText])){
    	articles = 2
    }else if(allEmpty([form.articles[2].title, form.articles[2].imageURL, form.articles[2].text, form.articles[2].buttonText, form.articles[2].buttonTwoText])){
    	articles = 3
    }else{
    	articles = 1
    }

    //copy article 1 name and url to 2nd column if empty.
    if (isEmpty(form.articles[1].name)) {
		form.articles[1].name = form.articles[0].name;
	}
	if (isEmpty(form.articles[1].URL)) {
		form.articles[1].URL = form.articles[0].URL
	}

	// If only 1 article is used AND layout is not 100 then..., move content to other column
	// so that image is in 1 column, and the text in the other column
	if (form.contentLayout != 100 && articles === 1) {
		form.articles[1].title = form.articles[0].title;
		form.articles[1].subtitle = form.articles[0].subtitle;
		form.articles[1].buttonText = form.articles[0].buttonText;
		form.articles[1].buttonStyle = form.articles[0].buttonStyle;
		form.articles[1].buttonTwoText = form.articles[0].buttonTwoText;
		form.articles[1].buttonTwoStyle = form.articles[0].buttonTwoStyle;
		form.articles[1].URL2 = form.articles[0].URL2;
		form.articles[1].articleBorder = form.articles[0].articleBorder;
		form.articles[1].articleTopSpacing = form.articles[0].articleTopSpacing;
		form.articles[1].articleBottomSpacing = form.articles[0].articleBottomSpacing;
		form.articles[1].text = form.articles[0].text;

		form.articles[0].title = '';
		form.articles[0].subtitle = '';
		form.articles[0].buttonText = '';
		form.articles[0].buttonStyle = '';
		form.articles[0].buttonTwoText = '';
		form.articles[0].buttonTwoStyle = '';
		form.articles[0].URL2 = '';
		form.articles[0].articleBorder = false;
		form.articles[0].text = '';
		form.articles[0].articleTopSpacing = 0;
		form.articles[0].articleBottomSpacing = 0;
	}
	
	// flip the article order
	if (form.columnDirection) {
		columnDirection = "ltr"
		innerPaddingRight = "innerPaddingRight"
		innerPaddingLeft = "innerPaddingLeft"
	}else{
		columnDirection = "rtl" 
		innerPaddingLeft = "innerPaddingRight"
		innerPaddingRight = "innerPaddingLeft"
	}

	//Set width per container depending on layout
	if (articles === 1 || articles === 2) {
		//if 1 or 2 articles are used, different layout options are possible
		if (form.contentLayout === 50) {
			columnOneWidth = containerWidth * 0.5
			columnOneClass = "column50"
			columnTwoWidth = containerWidth*0.5
			columnTwoClass = "column50"
		} else if(form.contentLayout === 70) {
			columnOneWidth = containerWidth*0.7
			columnOneClass = "column70"
			columnTwoWidth = containerWidth*0.3
			columnTwoClass = "column30"
		} else if(form.contentLayout === 30) {
			columnOneWidth = containerWidth*0.3
			columnOneClass = "column30"
			columnTwoWidth = containerWidth*0.7
			columnTwoClass = "column70"
		} else if(form.contentLayout === 40) {
			columnOneWidth = containerWidth*0.4
			columnOneClass = "column40"
			columnTwoWidth = containerWidth*0.6
			columnTwoClass = "column60"
		}
	}else if(articles === 3){
		//force 3 column layout for 3 articles
		columnOneWidth = containerWidth * 0.333
		columnOneClass = "column33"
		columnTwoWidth = containerWidth * 0.333
		columnTwoClass = "column33"
		columnThreeWidth = containerWidth * 0.333
		columnThreeClass = "column33"
	}
	//if layout is set to 100, it can overrule the 3 column layout.
	if(form.contentLayout === 100) {
		columnOneWidth = containerWidth
		columnOneClass = "column100"
		columnTwoWidth = containerWidth
		columnTwoClass = "column100"
		columnThreeWidth = containerWidth
		columnThreeClass = "column100"
	}


	//Define the width of each image
	//this depends on the padding setting but also on the layout settings (defined before this)
	if (form.settingPadding){
		html.column[0].imageWidth = columnOneWidth-(padding*2) //calc width in px
		html.column[1].imageWidth = columnTwoWidth-(padding*2) //calc width in px
		html.column[2].imageWidth = columnThreeWidth-(padding*2) //calc width in px
	}else{
		html.column[0].imageWidth = columnOneWidth
		html.column[1].imageWidth = columnTwoWidth
		html.column[2].imageWidth = columnThreeWidth
	}


	//Set HTML elemts specific to brand.
	//fonts uses
	//button styles
	if (form.settingTemplate === "renault"){
		html.fontStandard = "color:"+html.fontColor+";font-weight: 400; font-family: Arial, \'Helvetica Neue\', Helvetica, sans-serif, \'NouvelR Regular\'; font-size: 16px; line-height: 26px;text-align:"+form.textAlignment+";";
		html.fontTitle = "color:"+html.fontColor+";font-weight: 700; font-family: Arial, \'Helvetica Neue\', Helvetica, sans-serif, \'NouvelR Bold\';font-size: 30px; line-height: 34px;text-align:"+form.textAlignment+";";
		html.fontSubtitle = "color:#666666; font-weight: 600; font-family: Arial, \'Helvetica Neue\', Helvetica, sans-serif, \'NouvelR Semibold\';font-size: 16px; line-height: 18px;text-align:"+form.textAlignment+";";
		html.fontButton = "color:"+html.buttonTextColor+"; font-weight: 700; font-family: Arial, \'Helvetica Neue\', Helvetica, sans-serif, \'NouvelR Bold\'; font-size: 16px; line-height: 16px;text-align:center;";

	}else if(form.settingTemplate === "dacia"){
		html.fontStandard = "color:"+html.fontColor+";font-weight: normal; font-family: Arial, \'Helvetica Neue\', Helvetica, sans-serif, \'Read Light\'; font-size: 16px; line-height: 22px;text-align:"+form.textAlignment+";";
		html.fontTitle = "color:"+html.fontColor+";font-weight: 700; font-family: Helvetica, Arial, sans-serif, \'Dacia Block\';font-size: 34px; line-height: 36px;text-align:"+form.textAlignment+";";
		html.fontSubtitle = "color:"+html.fontColor+";font-weight: 600; font-family: Helvetica, Arial, sans-serif, \'Dacia Block\';font-size: 28px; line-height: 32px;text-align:"+form.textAlignment+";";
		html.fontButton = "color:"+html.buttonTextColor+"; font-weight: bold; font-family: Arial, \'Helvetica Neue\', Helvetica, sans-serif, \'Read Bold\'; font-size: 14px; line-height: 16px;text-align:center;ext-transform: uppercase;";

	}else if(form.settingTemplate === "jbl"){
		html.fontStandard = "color:"+html.fontColor+"; font-family:\'HelveticaNeueW01-75Bold\',arial,helvetica,sans-serif;font-size: 14px;line-height: 24px;text-align:"+form.textAlignment+";";
		html.fontTitle = "color:"+html.fontColor+"; font-family:\'HelveticaNeueW01-75Bold\',arial,helvetica,sans-serif;font-size: 30px;line-height: 34px;text-align:"+form.textAlignment+";";
		html.fontSubtitle = "color:"+html.fontColor+"; font-family:\'HelveticaNeueW01-75Bold\',arial,helvetica,sans-serif;font-size: 18px;line-height: 20px;text-align:"+form.textAlignment+";";
		html.fontButton = "color:"+html.buttonTextColor+"; font-family:\'HelveticaNeueW01-75Bold\',arial,helvetica,sans-serif;font-size: 14px;line-height: 24px; text-align:"+form.textAlignment+"; text-transform: uppercase; font-weight:700;";

	}else if(form.settingTemplate === "harman"){
		html.fontStandard = "color:"+html.fontColor+"; font-family:\'HelveticaNeueW01-75Bold\',arial,helvetica,sans-serif;font-size: 14px;line-height: 24px;text-align:"+form.textAlignment+";";
		html.fontTitle = "color:"+html.fontColor+"; font-family:\'HelveticaNeueW01-75Bold\',arial,helvetica,sans-serif;font-size: 30px;line-height: 34px;text-align:"+form.textAlignment+";";
		html.fontSubtitle = "color:"+html.fontColor+"; font-family:\'HelveticaNeueW01-75Bold\',arial,helvetica,sans-serif;font-size: 18px;line-height: 20px;text-align:"+form.textAlignment+";";
		html.fontButton = "color:"+html.buttonTextColor+"; font-family:\'HelveticaNeueW01-75Bold\',arial,helvetica,sans-serif;font-size: 14px;line-height: 24px; text-align:"+form.textAlignment+"; text-transform: uppercase; font-weight:700;";

	}else{
		//springbok
		html.fontStandard = "color:"+html.fontColor+"; font-family:\'Helvetica Neue\', Tahoma, sans-serif;font-size: 14px;line-height: 24px;text-align:"+form.textAlignment+";";
		html.fontTitle = "color:"+html.fontColor+"; font-family:\'Helvetica Neue\', Tahoma, sans-serif;font-size: 20px;line-height: 26px;text-align:"+form.textAlignment+";";
		html.fontSubtitle = "color:"+html.fontColor+"; font-family:\'Helvetica Neue\', Tahoma, sans-serif;font-size: 18px;line-height: 20px;text-align:"+form.textAlignment+";";
		html.fontButton = "color:"+html.buttonTextColor+"; font-family:\'Helvetica Neue\', Tahoma, sans-serif;font-size: 15px;line-height: 25px; text-align:"+form.textAlignment+";";
	}

	

	const generateButton = function(style: string, text:string, url:string, trackingName:string, radius:string, padding:string) {
		switch(style) {
			case "regular":
				return "<td style=\"float:left; padding-right: 10px; padding-bottom:"+paddingBottom+"px; "+html.fontButton+"\"><a href='%%=RedirectTo(\'"+url+"\')=%%' target='_blank' convert='true' alias='"+trackingName+"_CTA' style=\"text-align:center;border-radius:"+radius+"; background:"+html.buttonColor+"; border: solid 2px "+html.buttonColor+"; text-decoration: none; padding:"+padding+"; color: inherit; display:block; mso-padding-alt:0;\"><!--[if mso]><i style=\"letter-spacing: 15px;mso-font-width:-100%;mso-text-raise:20pt\" hidden>&nbsp;</i><![endif]--><span style=\"mso-text-raise:10pt;\">"+text+"</span><!--[if mso]><i style=\"letter-spacing: 15px;mso-font-width:-100%\" hidden>&nbsp;</i><![endif]--></a></td>";
			case "border":
				return "<td style=\"float:left; padding-right: 10px; padding-bottom:"+paddingBottom+"px; "+html.fontButton+"\"><a href='%%=RedirectTo(\'"+url+"\')=%%' target='_blank' convert='true' alias='"+trackingName+"_CTA' style=\"text-align:center;border-radius:"+radius+"; border: solid 2px "+html.buttonColor+"; text-decoration: none; padding:"+padding+"; color:"+html.buttonColor+"; display:block; mso-padding-alt:0;\"><!--[if mso]><i style=\"letter-spacing: 15px;mso-font-width:-100%;mso-text-raise:20pt\" hidden>&nbsp;</i><![endif]--><span style=\"mso-text-raise:10pt;\">"+text+"</span><!--[if mso]><i style=\"letter-spacing: 15px;mso-font-width:-100%\" hidden>&nbsp;</i><![endif]--></a></td>";
			case "textual":
				return "<td style=\"float:left; padding-right: 10px; padding-bottom:"+paddingBottom+"px; "+html.fontButton+"\"><a href='%%=RedirectTo(\'"+url+"\')=%%' target='_blank' convert='true' alias='"+trackingName+"_CTA' style=\"text-decoration: underline;color:"+html.buttonColor+"; display:block; mso-padding-alt:0;\"><span style=\"mso-text-raise:10pt;\">"+text+"</span></a></td>";
		}
	}








	/*---------------------------*/
	/* SET HTML CONTENT ELEMENTS */
	/*---------------------------*/
	/* complete <tr> with padding-bottom on <td> as internal spacing */

	for (let i = 0; i < 3; i++) {

		//extra attributes added to fix height
		if(form.articles[i].fixedDesktopHeight !== 0) {
			html.column[i].textHeight = "valign=\"top\" height=\""+form.articles[i].fixedDesktopHeight +"\""
		}

		//content row: desktop image
		if (form.articles[i].imageURL){
			html.column[i].desktopImage = "<tr><td style=\"padding-bottom:"+paddingBottom+"px;\" align='center'><a href='%%=RedirectTo(\'"+form.articles[i].URL+"\')=%%' target='_blank' convert='true' alias='"+form.articles[i].name+"_img'><img src='"+form.articles[i].imageURL+"' width='"+html.column[i].imageWidth+"' height='' alt='"+form.articles[i].name+"' border='0' style=\"width: 100%; max-width: "+html.column[i].imageWidth+"px; height: auto; display: block;\"></a></td></tr>"
		}

		//content row: title
		if (form.articles[i].title){
			if (form.articles[i].subtitle){
				//lower spacing
				html.column[i].title = "<tr><td style=\"padding-bottom:5px; "+html.fontTitle+"\"><h2 style=\"font-size: inherit; line-height: inherit; margin: 0;\">"+form.articles[i].title+"</h2></td></tr>"
			}else{
				//normal spacing
				html.column[i].title = "<tr><td style=\"padding-bottom:"+paddingBottom+"px; "+html.fontTitle+"\"><h2 style=\"font-size: inherit; line-height: inherit; margin: 0;\">"+form.articles[i].title+"</h2></td></tr>"
			}
		}

		//content row: subtitle
		if (form.articles[i].subtitle){
			html.column[i].subTitle = "<tr><td style=\"padding-bottom:"+paddingBottom+"px; "+html.fontSubtitle+"\">"+form.articles[i].subtitle+"</td></tr>"
		}

		//content row: text
		if (form.articles[i].text){

			if (form.articles[i].text!.includes('<ul>')){
				form.articles[i].text = form.articles[i].text!.replaceAll('<ul>', '<table wdith=\'100%\'>');
				form.articles[i].text = form.articles[i].text!.replaceAll('<li>', '<tr><td valign="top" align="center" style="width:10px;padding-right:5px;"><span style="margin-top:3px">'+form.bulletStyle+'</span></td><td style=\"text-align:left;\">');
				form.articles[i].text = form.articles[i].text!.replaceAll('</li>', '</td></tr>');
				form.articles[i].text = form.articles[i].text!.replaceAll('</ul>', '</table>');
			}
			if (form.articles[i].text!.includes('<a href')){
				form.articles[i].text = form.articles[i].text!.replaceAll('<a href', '<a style=\'color:inherit;\' href');
			}
			html.column[i].text = "<tr><td "+html.column[i].textHeight+" style=\"padding-bottom:"+paddingBottom+"px; "+html.fontStandard+"\">"+form.articles[i].text+"</td></tr>";
		}

		//content row: button
		if (form.articles[i].buttonText){
			// removed width 100% from <table> for center button alignment
			html.column[i].button = "<tr><td><table align=\"" + form.textAlignment + "\" width=\"\" border=\"0\" cellpadding=\"0\" cellspacing=\"0\" role=\"presentation\"><tr>";
			html.column[i].button += generateButton(form.articles[i].buttonStyle || "regular", form.articles[i].buttonText||"", form.articles[i].URL || "", form.articles[i].name || "", html.buttonBorderRadius, html.buttonPadding)

			if (form.articles[i].buttonTwoText){
				html.column[i].button += generateButton(form.articles[i].buttonTwoStyle || "regular", form.articles[i].buttonTwoText||"", form.articles[i].URL2 || "", form.articles[i].name || "", html.buttonBorderRadius, html.buttonPadding)
			}

			//close button row
			html.column[i].button += "</tr></table></td></tr>"
		}



	}

	/* UPDATE 12-01-22

	   OLD:
	   - 1. container <table><tr> or <div>
	   - 2. column <td> or <div>
	   - 3. contentStart <table>
	   - 4. content (e.g. title) <tr><td> + padding settings


		
		Background image @1
		difficult to border around content within the padding




	   NEW?:
	   - 1. container <table><tr> or <div>
	   - 2. column <td> or <div>
	   - note: these columns do not yet have padding. edge-to-edge
	   - 3. contentHeader <table>
	   - 4. contentStart <tr><td>
	   - note: this is where padding is added. Multiple "contentContainers" can be added based on when padding is required
	   - 4 contentstart <table>
	   - note: this is where a border and another padding can be set. (using table attribute "cellpadding")
	   - 5 content elements (e.g. title) are added <tr>


	   __________________________________________________________________________________________
	   | 1. container 																			|
	   |	(<table><tr>)<div>																	|
	   |	settings:																			|
	   |	- columnDirection																	|
	   |	- containerWidth																	|
	   |	- backgroundColor																	|
	   |	- (to add background image)															|
	   |________________________________________________________________________________________|
	   | 2A. column 0					|	2B. column 1			|	2C. column 	2			|
	   | <td> or <div>					|	<td> or <div>			|	<td> or <div>			|
	   | Settings:						|							|							|
	   | - Name							|							|							|
	   | - columnOneWidth				|							|							|
	   | - columnOneClass				|							|							|
	   | - moduleTopSpacing				|							|							|
	   | - moduleBottomSpacing			|							|							|
	   | 								|							|							|
	   | ______________________________ | _________________________ | _________________________ |
	   ||							   |||						   |||						   ||
	   || 3.contentHeader			   |||	3.contentHeader		   |||	3.contentHeader		   ||
	   || <table>					   |||	<table>				   |||	<table>			       ||
	   || Settings:					   |||						   |||					       ||
	   || 							   |||						   |||					       ||
	   ||______________________________|||_________________________|||_________________________||
	   || 							   ||| 						   ||| 					 	   ||
	   || 	(option)		 		   ||| 					  	   ||| 					 	   ||
	   || 	4B. contentStart 		   |||  paddingClassOption:	   ||| 					 	   ||
	   ||	<tr><td>padding L/R:0  	   ||| 	- innerPaddingBoth	   ||| 					 	   ||
	   ||	<table>				   	   ||| 	when its a middle col  ||| 					 	   ||
	   ||	Setting:				   ||| 	when its a middle col  ||| 					 	   ||
	   || 	- paddingBottom	(Y)		   ||| 	- innerPaddingLeft*    ||| 					 	   ||
	   || 	- articleBorder			   ||| 	when on the outside	   ||| 					 	   ||
	   || ____________________________ ||| 						   ||| 					 	   ||
	   ||| 							  |||| 						   ||| 					 	   ||
	   ||| 	5. content row	 		  |||| 						   ||| 					 	   ||
	   ||| 	<tr>...</tr>	 		  |||| 						   ||| 					 	   ||
	   |||- image---------------------|||| 						   ||| 					 	   ||
	   |||____________________________|||| 						   ||| 					 	   ||
	   || 	</table></td></tr>		   ||| 						   ||| 					 	   ||
	   || 							   ||| 						   ||| 					 	   ||
	   || 	(always there)	 		   ||| 					  	   ||| 					 	   ||
	   || 	4A. contentStart 		   ||| 					  	   ||| 					 	   ||
	   ||	<td><tr> padding L/R:(X)px ||| 						   ||| 					 	   ||
	   || 	<table>					   ||| 						   ||| 					 	   ||
	   || 	Settings:				   ||| 						   ||| 					 	   ||
	   || 	- padding L/R			   ||| 						   ||| 					 	   ||
	   || 	- Class: innerPadding*	   ||| 						   ||| 					 	   ||
	   || 	- paddingBototm	(Y)		   ||| 						   ||| 					 	   ||
	   || 	- article border    	   ||| 						   ||| 					 	   ||
	   || ____________________________ ||| 						   ||| 					 	   ||
	   || | 						 | ||| 						   ||| 					 	   ||
	   || | 5. content table's		 | ||| 						   ||| 					 	   ||
	   || | <tr>...</tr>			 | ||| 						   ||| 					 	   ||
	   || |- image-------------------| ||| 						   ||| 					 	   ||
	   || |- text--------------------| ||| 						   ||| 					 	   ||
	   || |- button------------------| ||| 						   ||| 					 	   ||
	   || |- ..... ------------------| ||| 						   ||| 					 	   ||
	   || |__________________________| ||| 						   ||| 					 	   ||
	   || 							   ||| 						   ||| 					 	   ||
	   || /4 </table></td></tr>		   ||| 						   ||| 					 	   ||
	   ||______________________________|||_________________________|||_________________________||
	   || /3. </table>				   ||| 						   ||| 					 	   ||
	   ||______________________________|||_________________________|||_________________________||
	   | 								|							|							|
	   | 								|							|							|
	   | /2. closeColumn				|							|							|
	   | </td> or </div>				|							|							|
	   | 								|							|							|
	   | 								|							|							|
	   |________________________________|___________________________|___________________________|
	   | /1 closeContainer																		|
	   | </div>(</tr></table>)																	|
	   |________________________________________________________________________________________|
	*/

	//1. container <table><tr> and <div>
	html.headerContainer = "<!--[if true]><table dir='"+columnDirection+"' role='presentation' width='"+containerWidth+"' style=\"all:unset;opacity:0; background:"+form.backgroundColor+";\"><tr><![endif]--><!--[if false]></td></tr></table><![endif]--><div dir='"+columnDirection+"' class='container' style=\"display:table;width:100%; background:"+form.backgroundColor+";\">"
	//2A. column-0-Start <td> or <div>
	const generateColumnStart = function(name: string, columnClass:string, width:number, topSpacing:number, bottomSpacing:number) {
		return "<!==%%=BeginImpressionRegion('"+ name +"')=%%==><!--[if true]><td valign='top' width='"+width+"' style='padding-top:" + topSpacing + "px;padding-bottom:" + bottomSpacing + "px;'><![endif]--><!--[if !true]><!--><div class='"+columnClass+"' width='"+width+"' style=\"padding-top:" + topSpacing + "px;padding-bottom:" + bottomSpacing + "px;display:inline-block;width:100%;vertical-align:top; max-width:"+width+"px;\"><!--<![endif]-->"
	}
	//3. contentHeader <table>
	html.headerContent = "<table dir='ltr' class='contentTable' width='100%' border='0' cellpadding='0' cellspacing='0' role='presentation'>"
	//4A or 4B. contentSart <tr><td><table>
	const generateContentStart = function(innerPaddingClass: string, togglePadding:boolean, articleBorder:boolean) {
		if (togglePadding && !articleBorder){
			return "<tr><td class='"+innerPaddingClass+"' style=\"padding-left:"+padding+"px; padding-right:"+padding+"px;\"><table align=\"" + form.textAlignment + "\" width=\"100%\" border=\"0\" cellpadding=\"0\" cellspacing=\"0\" role=\"presentation\">"
		}else if(togglePadding && articleBorder){
			return "<tr><td class='"+innerPaddingClass+"' style=\"padding-left:"+padding+"px; padding-right:"+padding+"px;\"><table align=\"" + form.textAlignment + "\" width=\"100%\" border=\"0\" cellpadding=\"20\" cellspacing=\"0\" role=\"presentation\" style='border:1px solid " + html.fontColor +"'>"
		}else if (!togglePadding && articleBorder){
			return "<tr><td style=\"padding: 0px;\"><table align=\"" + form.textAlignment + "\" width=\"100%\" border=\"0\" cellpadding=\"20\" cellspacing=\"0\" role=\"presentation\" style='border:1px solid " + html.fontColor +"'>"
		}else if (!togglePadding && !articleBorder){
			return "<tr><td style=\"padding: 0px;\"><table align=\"" + form.textAlignment + "\" width=\"100%\" border=\"0\" cellpadding=\"0\" cellspacing=\"0\" role=\"presentation\">"
		}
	}
		//5 content (see below ) per <tr>...</tr>
	// /3 + /4 closeContent  </table></td></tr></table>
	html.closeContent = "</table></td></tr></table>"
	// /2 close column </td> or </div>
	html.closeColumn = "<!--[if !true]><!--></div><!--<![endif]--><!--[if true]></td><![endif]--><!== %%=EndImpressionRegion(0)=%% ==>"
	// /1 close container </div> and </tr></table>
	html.closeContainer = "</div><!--[if true]></tr></table><![endif]-->"
	

	/*-----------------------*/
	/* OUTPUT HTML STRUCTURE */
	/*-----------------------*/

	//1. container
	output = html.headerContainer

	//2. Start column(s)
	if( form.contentLayout === 100 ){
		/* layout 100:
			- columnClass = "column100"
			- columnWidth = containerWidth
			- innerPaddingClass = ""
		*/
		for (let i = 0; i < articles; i++) {
			output += generateColumnStart(form.articles[i].name||"columnStart","column100",containerWidth,form.articles[i].articleTopSpacing||0,form.articles[i].articleBottomSpacing||0);
			output += html.headerContent;

			//4. Start Content
			if (!form.settingPadding && form.articles[i].imageURL){
				//an image without padding is required. open contentStart without padding
				output += generateContentStart("", false, form.articles[i].articleBorder || false) + html.column[i].desktopImage + "</table></td></tr>"
				//after the image, continue with regulat contentStart (with padding)
				output += generateContentStart("", true, form.articles[i].articleBorder || false);
			}else{
				output += generateContentStart("", true, form.articles[i].articleBorder || false) + html.column[i].desktopImage
			}
			//add content that always has a padding around it
			output += html.column[i].title + html.column[i].subTitle + html.column[i].text + html.column[i].button;
			output += html.closeContent + html.closeColumn;
		}

	}else if (articles === 3){
		/* layout 33%:
			- columnClass = "column33"
			- columnWidth = containerWidth/3
			- innerPaddingClass = innerPaddingLeft, innerPaddingBoth, innerPaddingRight
		*/
		for (let i = 0; i < articles; i++) {
			if (i == 0){innerPaddingClass = innerPaddingRight}
			if (i == 1){innerPaddingClass = "innerPaddingBoth"}
			if (i == 2){innerPaddingClass = innerPaddingLeft}

			output += generateColumnStart(form.articles[i].name||"columnStart","column33",containerWidth/3,form.articles[i].articleTopSpacing||0,form.articles[i].articleBottomSpacing||0);
			output += html.headerContent;

			if (!form.settingPadding && form.articles[i].imageURL){
				//an image without padding is required. open contentStart without padding
				output += generateContentStart(innerPaddingClass, false, form.articles[i].articleBorder || false) + html.column[i].desktopImage + "</table></td></tr>"
				//after the image, continue with regulat contentStart (with padding)
				output += generateContentStart(innerPaddingClass, true, form.articles[i].articleBorder || false);
			}else{
				output += generateContentStart(innerPaddingClass, true, form.articles[i].articleBorder || false) + html.column[i].desktopImage
			}
			//add content that always has a padding around it
			output += html.column[i].title + html.column[i].subTitle + html.column[i].text + html.column[i].button;
			output += html.closeContent + html.closeColumn;
		}

	}else{
		/* Two columns are required - size is depending on layout selected above*/
		for (let i = 0; i < 2; i++) {
			if (i == 0){innerPaddingClass = innerPaddingRight; columnClass = columnOneClass; columnWidth = columnOneWidth}
			if (i == 1){innerPaddingClass = innerPaddingLeft; columnClass = columnTwoClass; columnWidth = columnTwoWidth}

			//if padding = false and articles == 1 then we dont want the innerpaddingClass. (keep bigger spacing next to image)
			if (i == 1 && !form.settingPadding && articles === 1){ innerPaddingClass = "" }

			output += generateColumnStart(form.articles[i].name||"columnStart",columnClass,columnWidth,form.articles[i].articleTopSpacing||0,form.articles[i].articleBottomSpacing||0);
			output += html.headerContent;

			if (!form.settingPadding && form.articles[i].imageURL){
				//an image without padding is required. open contentStart without padding
				output += generateContentStart(innerPaddingClass, false, form.articles[i].articleBorder || false) + html.column[i].desktopImage + "</table></td></tr>"
				//after the image, continue with regulat contentStart (with padding)
				output += generateContentStart(innerPaddingClass, true, form.articles[i].articleBorder || false);
			}else{
				output += generateContentStart(innerPaddingClass, true, form.articles[i].articleBorder || false) + html.column[i].desktopImage
			}
			//add content that always has a padding around it
			output += html.column[i].title + html.column[i].subTitle + html.column[i].text + html.column[i].button;
			output += html.closeContent + html.closeColumn;
		}

	}
	// end container
	output += html.closeContainer


   return output;
}
export default generateHTML;